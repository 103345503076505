<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.edit">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row v-if="!loading && permission.edit">
      <b-col>
        <div><b-link :to="{ name: 'Accounts' }">back to accounts</b-link></div>
        <div class="mt-4"><strong>key</strong></div>
        <b-form-input type="text" v-model="form.key"></b-form-input>
        <div class="mt-4"><strong>organisation</strong></div>
        <b-form-input type="text" v-model="form.organisation" placeholder="Organisation short name"></b-form-input>
        <div class="mt-4"><strong>comments</strong></div>
        <b-form-input type="text" v-model="form.comments" placeholder="Optional comments"></b-form-input>
        <div class="mt-4"><strong>expiry</strong> (YYYY-MM-DD)</div>
        <b-form-input type="text" v-model="form.expiry" placeholder="2025-01-31"></b-form-input>
        <b-form-checkbox class="mt-4" v-model="form.demo" :value="true" :unchecked-value="false">demo account</b-form-checkbox>
        <div v-if="form.demo">
          <div class="mt-4"><strong>demo Licensee legal entity name</strong></div>
          <b-form-input type="text" v-model="form.demoLicensee" placeholder="Legal entity name"></b-form-input>
          <div class="mt-4"><strong>demo commencement date</strong> (YYYY-MM-DD)</div>
          <b-form-input type="text" v-model="form.demoCommencementDate" placeholder="Organisation short name"></b-form-input>
          <div class="mt-4"><strong>demo initial terms</strong></div>
          <b-form-input type="text" v-model="form.demoInitialTerm" placeholder="14 days from Commencement Date"></b-form-input>
        </div>
        <div>
          <div class="mt-4"><strong>publishers</strong></div>
          <b-form-checkbox class="mt-2" v-model="publishersAll" name="check-button" switch>
            All always on
          </b-form-checkbox>
          <b-button v-if="!publishersAll" v-b-toggle.sidebar-publishers variant="outline-primary" class="my-2">
            Publishers: {{form.publishers.length}} out of {{publishersOptions.length}}
          </b-button>
          <b-sidebar v-if="!publishersAll" id="sidebar-publishers" title="Publishers" backdrop backdrop-variant="transparent" shadow width="95%">
            <div class="px-3 py-2">
            <b-container>
              <b-row>
                <b-col>
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="form.publishers"
                    :options="publishersOptions"
                    name="publishers"
                    style="column-count: 2;"
                    stacked
                  ></b-form-checkbox-group>
                </b-col>
                <b-col>
                  <b-container>
                    <b-row>
                      <b-col>
                        <b-button variant="outline-primary" class="mb-4 mr-2" v-on:click="allPublishers">select all</b-button>
                        <b-button variant="outline-primary" class="mb-4 mr-2" v-on:click="nonePublishers">clear all</b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <h3>Quick add options</h3>
                        <div class="mb-4">Use buttons below to add groups of publishers to your selection</div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col v-if="$config.BUILD === 'full'">
                        <div>
                          <div><strong>Expert-led curation</strong></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level1')">RIC Level 1</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level2')">RIC Level 2</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('accounting')">Accounting</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('clients')">Clients</b-button></div>
                        </div>
                        <div>
                          <div><strong>Core</strong></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('ukcore')">UK Core</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('eucore')">EU Core</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('intcore')">International Core</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('uscore')">US Core</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('dataprivacy')">Data privacy</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('lifescience')">Life sciences</b-button></div>
                        </div>
                      </b-col>
                      <b-col>
                        <div><strong>Countries</strong></div>
                        <div v-for="country in countries" :key="country">
                          <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="selectCountry(country)">{{ country }}</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
            </div>
          </b-sidebar>
          <div class="mt-4"><strong>topics</strong></div>
          <b-button v-b-toggle.sidebar-topics variant="outline-primary" class="my-2">
              Topics: {{form.topics.length}} out of {{topicsOptions.length}}
          </b-button>
          <b-sidebar id="sidebar-topics" title="Topics" backdrop backdrop-variant="transparent" shadow width="95%">
            <div class="px-3 py-2">
              <b-form-group class="mb-4">
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="form.topics"
                  :options="topicsOptions"
                  name="topics"
                  style="column-count: 3;"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-sidebar>
          <div class="mt-4"><strong>special modules</strong></div>
          <div class="px-3 py-2">
            <b-form-group class="mb-4">
              <b-form-checkbox-group
                id="checkbox-group-3"
                v-model="form.acgroups"
                :options="acgroupsOptions"
                name="acgroups"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <div class="mt-4"><strong>home page</strong></div>
          <div class="px-3 py-2">
            <b-form-group class="mb-4">
              <b-form-select v-model="form.homepage" :options="homepageOptions"></b-form-select>
            </b-form-group>
          </div>
        </div>
        <b-button v-if="valid && !saving" class="mt-4" variant="outline-primary" @click="save">save</b-button>
        <div class="mt-4">
          <b-alert v-model="dismissCountDown" variant="success">
            Saved changes
          </b-alert>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import ac from '@/libs/accesscontrol'
import sourcesGroups from '@/libs/sourcesGroups.js'

export default {
  name: 'AccountForm',
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    valid: function () {
      const form = this.form
      let result = true
      if (form.key.length < 10) {
        result = false
      }
      if (!form.organisation) {
        result = false
      }
      if (moment(form.expiry).isSameOrBefore(moment())) {
        result = false
      }
      if (!form.expiry.match(/\d\d\d\d-\d\d-\d\d/)) {
        result = false
      }
      if (form.demo) {
        if (!form.demoLicensee) {
          result = false
        }
        if (!form.demoInitialTerm) {
          result = false
        }
        if (moment(form.expiry).isSameOrBefore(form.demoCommencementDate)) {
          result = false
        }
        if (!form.demoCommencementDate.match(/\d\d\d\d-\d\d-\d\d/)) {
          result = false
        }
      }
      return result
    }
  },
  created: async function () {
    this.$stat.log({ page: 'account form', action: 'open account form', model: 'account', model_id: this.$route.params.id })
    this.permission.edit = ac.can(this.user.acgroups).updateAny('account').granted
    this.sources = await this.$Amplify.API.get('cosmos', '/sources')
    this.publishersOptions = _.uniq(this.sources.map(x => x.publisher)).sort()
    this.countries = _.uniq(this.sources.map(x => x.countryName)).sort()
    const topics = await this.$Amplify.API.get('cosmos', '/topics/simple')
    _.remove(topics, x => {
      return x.name.includes('ARCHIVED')
    })
    this.topicsOptions = _.map(topics, x => { return { text: x.name, value: x.id } })
    if (this.$route.params.id) {
      this.edit = true
      this.load()
    } else {
      const key = uuidv4()
      this.form.key = 'XXX-0X-' + key
      this.form.expiry = moment().add(15, 'days').format('YYYY-MM-DD')
      this.loading = false
    }
  },
  data () {
    return {
      acgroupsOptions: ['cpdvisitor', 'initiativesvisitor', 'insightsvisitor', 'marvisitor'],
      countries: [],
      dismissCountDown: 0,
      edit: false,
      form: {
        acgroups: [],
        comments: '',
        demo: false,
        demoCommencementDate: null,
        demoInitialTerm: null,
        demoLicensee: null,
        expiry: '',
        homepage: 'Start',
        key: '',
        organisation: '',
        publishers: [],
        topics: []
      },
      homepageOptions: [
        { value: 'Start', text: 'Regulatory Horizon - Start' },
        { value: 'ImpactInitiatives', text: 'Initiatives - ImpactInitiatives' },
        { value: 'ImpactPoliciesDashboard', text: 'Climate Policy Dashboard - ImpactPoliciesDashboard' }
      ],
      loading: true,
      item: null,
      permission: {
        edit: false,
        read: false
      },
      publishersAll: false,
      publishersOptions: [],
      saving: false,
      sources: null,
      topicsOptions: []
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        const apiName = 'cosmos'
        const path = `/standard/account/item/${this.$route.params.id}`
        this.item = await this.$Amplify.API.get(apiName, path)
        let publishers = null
        if (this.item.publishers === 'ALL') {
          publishers = 'ALL'
          this.publishersAll = true
        } else {
          publishers = JSON.parse(this.item.publishers)
          this.publishersAll = false
        }
        this.form = {
          acgroups: JSON.parse(this.item.acgroups),
          comments: this.item.comments,
          demo: this.item.demo,
          demoCommencementDate: this.item.demoCommencementDate,
          demoInitialTerm: this.item.demoInitialTerm,
          demoLicensee: this.item.demoLicensee,
          homepage: this.item.homepage,
          expiry: this.item.expiry,
          key: this.item.key,
          organisation: this.item.organisation,
          publishers: publishers,
          topics: JSON.parse(this.item.topics)
        }
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    save: async function () {
      this.dismissCountDown = 0
      this.saving = true
      try {
        let publishers = null
        if (this.publishersAll) {
          publishers = 'ALL'
        } else {
          publishers = JSON.stringify(this.form.publishers)
        }
        const params = {
          body: {
            acgroups: JSON.stringify(this.form.acgroups),
            comments: this.form.comments,
            demo: this.form.demo,
            demoCommencementDate: this.form.demoCommencementDate,
            demoInitialTerm: this.form.demoInitialTerm,
            demoLicensee: this.form.demoLicensee,
            expiry: this.form.expiry,
            homepage: this.form.homepage,
            key: this.form.key,
            organisation: this.form.organisation,
            publishers: publishers,
            topics: JSON.stringify(this.form.topics)
          }
        }
        if (this.edit) {
          params.body.updated_by = this.user.username
          await this.$Amplify.API.put('cosmos', `/standard/account/${this.$route.params.id}`, params)
        }
        if (!this.edit) {
          params.body.created_by = this.user.username
          params.body.updated_by = this.user.username
          const newItem = await this.$Amplify.API.put('cosmos', '/standard/account', params)
          this.$router.push({ name: 'Account', params: { id: newItem.id } })
        }
      } catch (e) {
        this.$logger.warn(e)
      }
      this.dismissCountDown = 2
      this.saving = false
    },
    allPublishers: function () {
      this.form.publishers = this.publishers
    },
    selectCountry: function (country) {
      const publishers = []
      _.each(this.sources, source => {
        if (source.countryName === country) {
          publishers.push(source.publisher)
        }
      })
      this.form.publishers = _.uniq(_.concat(this.form.publishers, _.uniq(publishers)))
    },
    custom: function (selection) {
      const groups = sourcesGroups
      const newSelection = _.uniq(_.concat(this.form.publishers, groups[selection]))
      this.form.publishers = newSelection
    },
    nonePublishers: function () {
      this.form.publishers = []
    }
  },
  watch: {
    'form.demo': function () {
      if (this.form.demo) {
        this.form.demoCommencementDate = moment().add(1, 'days').format('YYYY-MM-DD')
        this.form.demoInitialTerm = '14 days from the Commencement Date'
      } else {
        this.form.demoCommencementDate = null
        this.form.demoInitialTerm = null
        this.form.demoLicensee = null
      }
    }
  }
}
</script>
